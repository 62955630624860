import Vue from 'vue'
import App from './App.vue'
import LayoutWrapper from './layouts/LayoutWrapper'
import VueGoodTablePlugin from 'vue-good-table'
import * as filters from './filters'
import './main.css'
import 'vue-good-table/dist/vue-good-table.css'

Vue.config.productionTip = false

import router from './router'

Vue.component('LayoutWrapper', LayoutWrapper)

Vue.use(VueGoodTablePlugin);

// Filters
Vue.filter('formatDate', filters.formatDate);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
