import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: function () {
      return import('../views/projects/Overview.vue')
    }
  },
  {
    path: '/projects/create',
    component: function () {
      return import('../views/projects/Create.vue')
    }
  },
  {
    path: '/accounts',
    component: function () {
      return import('../views/accounts/Overview.vue')
    }
  },
  {
    path: '/accounts/create',
    component: function () {
      return import('../views/accounts/Create.vue')
    }
  },
  {
    props: true,
    path: '/accounts/:accountId(\\d+)',
    component: function () {
      return import('../views/accounts/Update.vue')
    }
  },
  {
    props: true,
    path: '/projects/:projectId(\\d+)/recipients',
    meta: { layout: 'ProjectLayout' },
    component: function () {
      return import('../views/projects/recipients/Overview.vue')
    }
  },
  {
    props: true,
    path: '/projects/:projectId(\\d+)/recipients/:receiverId(\\d+)',
    meta: { layout: 'ProjectLayout' },
    component: function () {
      return import('../views/projects/recipients/Update.vue')
    }
  },
  {
    props: true,
    path: '/projects/:projectId(\\d+)/recipients/import',
    meta: { layout: 'ProjectLayout' },
    component: function () {
      return import('../views/projects/recipients/Import.vue')
    }
  },
  {
    props: true,
    path: '/projects/:projectId(\\d+)/messages',
    meta: { layout: 'ProjectLayout' },
    component: function () {
      return import('../views/projects/messages/Overview.vue')
    }
  },
  {
    props: true,
    path: '/projects/:projectId(\\d+)/messages/create',
    meta: { layout: 'ProjectLayout' },
    component: function () {
      return import('../views/projects/messages/Create.vue')
    }
  },
  {
    props: true,
    path: '/projects/:projectId(\\d+)/messages/:messageId(\\d+)',
    meta: { layout: 'ProjectLayout' },
    component: function () {
      return import('../views/projects/messages/Update.vue')
    }
  },
  {
    props: true,
    path: '/projects/:projectId(\\d+)/scheduling',
    meta: { layout: 'ProjectLayout' },
    component: function () {
      return import('../views/projects/Scheduling.vue')
    }
  },
  {
    props: true,
    path: '/projects/:projectId(\\d+)/settings',
    meta: { layout: 'ProjectLayout' },
    component: function () {
      return import('../views/projects/Settings.vue')
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
